export default {
    AfriendPhone: 'Un ami Numéro de téléphone:',

    wheelget: `Cliquez sur la roulette pour obtenir`,
    KSH: 'XAF',
    takeout: `Emporter`,
    only: `Il suffit d’en obtenir un autre `,
    towithdraw: `pour se retirer`,
    Expira: `Temps d’échéance`,
    Invite: `Invitez des amis pour vous aider à retirer`,
    allbets: `Bonus reçu`,
    Leaderboard: `Détails de l'invitation`,
    rank: `Classement`,
    user: `Utilisateur`,
    betamount: `Montant d'enjeu`,
    pddDesc: `Règles d'événement`,
    pddDesc1: `1. Le montant cumulé atteint`,
    pddDesc2: `2. Invitez vos amis à s'inscrire à ${window.$platformName}, cela vous aidera à retirer de l'argent plus rapidement. Si votre ami s'inscrit et joue le jeu, cela vous aidera encore à retirer de l'argent plus rapidement.`,
    pddDesc3: `3.Chaque utilisateur recevra un tour gratuit par jour.`,
    pddDesc4: `4. Chaque utilisateur est valide pour 3 jours par événement et peut participer à l'événement plusieurs fois.`,
    pddDesc5: `5. Si vous êtes récompensé avec le montant de l'événement, vous pouvez jouer gratuitement au jeu et gagner plus d'argent réel.`,
    pddDesc6: `6. Si le retrait n'est pas effectué dans les 3 jours, l'argent sera perdu.`,

    GetKsh: 'Obtenez XAF ',
    frofree: 'gratuitement',
    SACAR: 'SACAR',
    takewrong: `si ce n'est pas le cas`,

    FRIENDEARN: 'AIDEZ UN AMI À GAGNER',
    Afterregistering: 'Après votre inscription, vous pouvez participer à des activités pour gagner des XAF',
    aftersign: `Après vous être connecté, vous pouvez également Après vous être inscrit, vous pouvez participer à l'événement pour gagner'`,
    invitedyou: `vous invite à rejoindre l'événement pour gagner`,
    helphim: `Connectez-vous pour l'aider à gagner`,
    Helpfriend: 'Aidez un ami à gagner' ,
    youfriend: 'Ton ami' ,

    pleasetry: `veuillez réessayer`,
}